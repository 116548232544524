<template>
  <div class="vasion-flex vasion-calc-height">
    <router-view class="vasion-remaining-after-two-subnavs" />
  </div>
</template>

<script>
export default {
  name: 'TheReport',
  components: {},
  computed: {
  },
}
</script>

<style lang="scss" scoped>
</style>
